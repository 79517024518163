var Config = {

    app_key: 'qwe#r$s%s&d*r!w*e((f))d-f`werh14445`4rt5`4ert5`4rt31645k132v132',
    // api: 'https://api.flatcoding.com',
    api:'https://api.flatcoding.com', 
    //api:'http://localhost:3000/api', 
    captcha: {
        public: '6LefOUopAAAAAERp42vXnixQ6WAUcq7VAnyHjwlt',
        secret:'6LefOUopAAAAAKDP80U5BQ8v-U10T_H2IF0WCZ9P'
    }
    
} 
 
export default Config; 
